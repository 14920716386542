var slideImages = []
var slideIndex = 0

AWS.config.region = 'us-east-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: ''
})

var albumsBucketName = 'cloudfront-cdn.babylonbreastcancer.org'
var cdnUrl = 'https://cdn.babylonbreastcancer.org/';

var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    region: 'us-east-2',
    params: {Bucket: albumsBucketName}
})


function openGallery(sender) {
    var galleryName = $(sender).data('gallery');
    console.log('loading ' + galleryName)
    loadGallery(galleryName);

    $(".overlay").click(function (event) {
        slideIndex = 0;
        $(".overlay").fadeOut()
        $('#galleryImages').find('.mySlides').remove()
        $("body").css('overflow', 'auto')
    })

    $(".gallery-images").click(function (event) {
        event.stopPropagation();
    })

}

function loadGallery(galleryName) {
    var albumKey = galleryName + '/'
    s3.listObjects({Prefix: albumKey}, function (err, data) {
        if (err) throw err
        var images = data.Contents.map(function (item) {
            return cdnUrl + item.Key
        })
        createGalleryHtml(images.splice(1))
    })
}

function createGalleryHtml(images) {
    var i;
    for (i = 0; i < images.length; i++) {
        var elemDiv = document.createElement("div");
        elemDiv.setAttribute("class", "mySlides");
        var elemImage = document.createElement("img");
        elemImage.src = images[i];
        elemDiv.appendChild(elemImage);
        document.getElementById("galleryImages").appendChild(elemDiv);
    }

    slideImages = document.getElementsByClassName('mySlides');
    slideIndex = 0;
    slideImages[0].style.display = 'block';
    for (j = 1; j < slideImages.length; j++) {
        slideImages[j].style.display = 'none';
    }

    //iterates through images and sets up slider display options
    slideImages = document.getElementsByClassName('mySlides');
    slideIndex = 0;
    slideImages[0].style.display = 'block';
    for (j = 1; j < slideImages.length; j++) {
        slideImages[j].style.display = 'none';
    }

    $('.overlay').fadeIn()

}

function onPreviousSlide() {
    slideImages[slideIndex].style.display = 'none';

    slideIndex--;
    if (slideIndex < 0) {
        slideIndex = slideImages.length - 1;
    }
    slideImages[slideIndex].style.display = 'block';
}

function onNextSlide() {
    slideImages[slideIndex].style.display = 'none';

    slideIndex++;
    if (slideIndex > slideImages.length - 1) {
        slideIndex = 0
    }
    slideImages[slideIndex].style.display = 'block';
}
